import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import SEO from '../../components/App/SEO';
import exampleMetaImage from '../../assets/images/examples/example-meta-image.png';
import StartProject from '../../components/Common/StartProject';
import {StaticImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';


// Functional component
const Examples = () => {
  return (
	<Layout>
	  <SEO
		title="キャンペーン事例｜応募から抽選まで一括管理できるツール"
		desc="個人から中小企業・大企業まで、リタッチを使ったキャンペーン事例を紹介。LINE・TwitterなどのSNSからレシートやアンケートなどの60以上の応募方法まで対応。抽選、ハッシュタグ機能が充実。"
		banner={exampleMetaImage}
		pathname={'/examples'}
		type={'Article'}
		shortName="キャンペーン事例"
		createdAt="2021-03-02"
	  />
	  <Navbar />
	  <PageBanner
		pageTitle="事例・サンプル"
		homePageText="Home"
		homePageUrl="/"
		activePageText="事例・サンプル"
	  />
	  <section className="solutions-area pt-100 pb-70">
		<div className="container">
		  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Platform Services
                    </span>
			<h2>SNSキャンペーン
			</h2>
		  </div>

		  <div className="row">
			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fab fa-twitter" style={{color:'#1DA1F2'}}></i>
				</div>
				<h3>
				  <Link to="/examples/twitter">
					Twitter
				  </Link>
				</h3>
				<Link to="/examples/twitter" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>
			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fab fa-line"  style={{color:'#00c300'}}></i>
				</div>
				<h3>
				  <Link to="/examples/line">
					LINE
				  </Link>
				</h3>
				<Link to="/examples/line" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>

			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fab fa-instagram"  style={{color:'#E1306C'}}></i>
				</div>
				<h3>
				  <Link to="/examples/instagram">
					Instagram
				  </Link>
				</h3>
				<Link to="/examples/instagram" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>
			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fab fa-youtube"  style={{color:'#FF0000'}}></i>
				</div>
				<h3>
				  <Link to="/examples/youtube">
					Youtube
				  </Link>
				</h3>
				<Link to="/examples/youtube" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>

		  </div>
		</div>
	  </section>
	  <section className="solutions-area pt-100 pb-70">
		<div className="container">
		  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        CASE STUDY
                    </span>
			<h2>よくあるキャンペーン
			</h2>
			{/*https://coolors.co/f94144-f3722c-f8961e-f9844a-f9c74f-90be6d-43aa8b-4d908e-577590-277da1*/}
			{/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>*/}
		  </div>

		  <div className="row">

			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fas fa-percent"  style={{color:'#F94144'}}></i>
				</div>
				<h3>
				  <Link to="/examples/coupon">
					クーポン配布
				  </Link>
				</h3>

				<Link to="/examples/coupon" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>
			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fas fa-receipt" style={{color:'#4D908E'}}></i>
				</div>
				<h3>
				  <Link to="/examples/receipt">
					レシート<br />キャンペーン
				  </Link>
				</h3>
				{/*<p>レシートを応募資格とした</p>*/}

				<Link to="/examples/receipt" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>
			{/*<div className="col-lg-3 col-sm-6">*/}
			{/*    <div className="single-solutions-box">*/}
			{/*        <div className="icon">*/}
			{/*            <i className="fas fa-hashtag"></i>*/}
			{/*        </div>*/}
			{/*        <h3>*/}
			{/*            <Link to="/service-details">*/}
			{/*                ハッシュタグキャンペーン*/}
			{/*            </Link>*/}
			{/*        </h3>*/}
			{/*        <Link to="/service-details" className="view-details-btn">*/}
			{/*            詳細を見る*/}
			{/*        </Link>*/}
			{/*    </div>*/}
			{/*</div>*/}
			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fas fa-music"  style={{color:'#F8961E'}}></i>
				</div>
				<h3>
				  <Link to="/examples/pre-add-pre-save">
					プリアド・<br/>プリセーブ
				  </Link>
				</h3>
				<Link to="/examples/pre-add-pre-save" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>
			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="far fa-images" style={{color:'#277DA1'}}></i>
				</div>
				<h3>
				  <Link to="/examples/photo-contest">
					フォトコンテスト
				  </Link>
				</h3>
				<Link to="/examples/photo-contest" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>
			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fas fa-barcode"  style={{color:'#577590'}}></i>
				</div>
				<h3>
				  <Link to="/examples/must-by">
					マストバイ<br/>キャンペーン
				  </Link>
				</h3>
				<Link to="/examples/must-by" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>
			{/*<div className="col-lg-3 col-sm-6">*/}
			{/*    <div className="single-solutions-box">*/}
			{/*        <div className="icon">*/}
			{/*            <i className="fas fa-user-friends"></i>*/}
			{/*        </div>*/}
			{/*        <h3>*/}
			{/*            <Link to="/examples/share">*/}
			{/*                シェア・友だち紹介*/}
			{/*            </Link>*/}
			{/*        </h3>*/}
			{/*        <Link to="/examples/share" className="view-details-btn">*/}
			{/*            詳細を見る*/}
			{/*        </Link>*/}
			{/*    </div>*/}
			{/*</div>*/}
		  </div>
		</div>
	  </section>
	  <section className="solutions-area pt-100 pb-70">
		<div className="container">
		  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        CASE STUDY
                    </span>
			<h2>ビジネスタイプ
			</h2>
		  </div>
		  {/*https://coolors.co/ffbe0b-fb5607-ff006e-8338ec-3a86ff*/}
		  <div className="row">
			<div className="col-lg-3 col-sm-6">
			  <div className="single-solutions-box">
				<div className="icon">
				  <i className="fas fa-shopping-cart" style={{color:'#06d6a0'}}></i>
				</div>
				<h3>
				  <Link to="/examples/e-commerce">
					ECサイト
				  </Link>
				</h3>
				<Link to="/examples/e-commerce" className="view-details-btn">
				  詳細を見る
				</Link>
			  </div>
			</div>
			<div className="col-lg-3 col-sm-6">
				<div className="single-solutions-box">
					<div className="icon">
						<i className="fas fa-store" style={{color:'#ffd166'}}></i>
					</div>
					<h3>
						<Link to="/examples/shop">
							ショップ
						</Link>
					</h3>
					<Link to="/examples/shop" className="view-details-btn">
						詳細を見る
					</Link>
				</div>
			</div>
			<div className="col-lg-3 col-sm-6">
				<div className="single-solutions-box">
					<div className="icon">
						<i className="fas fa-user-circle" style={{color:'#ef476f'}}></i>
					</div>
					<h3>
						<Link to="/examples/influencer">
						  	インフルエンサー
						</Link>
					</h3>
					<Link to="/examples/influencer" className="view-details-btn">
						詳細を見る
					</Link>
				</div>
			</div>
			{/*<div className="col-lg-3 col-sm-6">*/}
			{/*	<div className="single-solutions-box">*/}
			{/*		<div className="icon">*/}
			{/*			<i className="fas fa-user-circle"></i>*/}
			{/*		</div>*/}
			{/*		<h3>*/}
			{/*			<Link to="/examples/game">*/}
			{/*			  	ゲーム配信<br/>*/}
			{/*			</Link>*/}
			{/*		</h3>*/}
			{/*		<Link to="/examples/game" className="view-details-btn">*/}
			{/*			詳細を見る*/}
			{/*		</Link>*/}
			{/*	</div>*/}
			{/*</div>*/}
		  </div>
		</div>
	  </section>
	  <StartProject />
	  <Footer />
	</Layout>
  );
}

export default Examples